.container{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;

}
.title{
    color: black;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 42px;
    line-height: 1.23; 
    @media screen and (max-width: 769px) {
       font-size: 32px;      
    }   
    @media screen and (max-width: 431px) {
        font-size: 28px;    
      }
}
.products{
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1025px) {
        gap: 20px;
    }
    @media screen and (max-width: 769px) {
        flex-direction: column;
        gap: 30px;       
    }
}
.product{
    position: relative;
    width: 360px;
    height: 250px; 
    @media screen and (max-width: 1025px) {
        width: 300px;
    }   
    @media screen and (max-width: 821px) and (min-width:768px) {
        width: 250px;
        height: 200px;
    } 
    @media screen and (max-width: 769px)  {
        width: 300px;
        height: 250px;
    } 
}
.gray{
    z-index: 10;
    position: absolute;
    height: 100%;
    width:100%;
    border-radius: 15px;
    box-shadow: inset 0 0 0 2000px rgba(71, 70, 70, 0.3);
    
}
.img{
    z-index: 2;
    animation: none;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}
.product:hover{
    animation-name: magnify;
}
@keyframes magnify{
    from{
        width: 360px;
        height: 250px;
    }
    to{
        width: 400px;
        height: 300px;
    }
}

.text{
    text-transform: uppercase;
    position: absolute;
    margin-top: 100px;
    margin-left: 50px;
    color: white;
    font-size: 26px;
    z-index: 14;
    
    // text-align: center;
    @media screen and (max-width: 1025px) {
        font-size: 20px;
       
    } 
    @media screen and (max-width: 821px) and (min-width:768px) {
       font-size: 16px;
    } 
    @media screen and (max-width: 769px) and (min-width:430px) {
        font-size: 24px;
    } 
    @media screen and (max-width: 431px) {
        font-size: 16px;    
      }
}