.authPage{
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding: 200px;
    padding-top: 80px;
    gap: 100px;
    @media screen and (max-width: 769px) {
        padding-top: 40px;
        gap: 10px;
    }
    @media screen and (max-width: 431px) {
        flex-direction: column;
        gap: 20px;    
      }
}
.content{
    width: 50vw;
    height: 80vh;
    border: solid 1px rgb(211, 211, 211);
    border-radius: 10px;
    text-align: center;
    @media screen and (max-width: 769px) {
        width: 30vw;
    }
    @media screen and (max-width: 431px) {
        width: 100vw;
        height: 300px;   
    }
}
.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: blue;
    align-self: center;
    
}

.text{
    text-decoration: none;
    color: white;
}