.photos{
    padding: 100px 10vw 10vw 10vw;
    display: flex;
    gap: 3vw;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1025px) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding-top: 0px;
      }
}
.vertical{
    display: flex;
    flex-direction: column;
    gap: 5vh;
}
.imgBig{
    width: 560px;
    height: 660px;
    @media screen and (max-width: 431px) {
        width: 300px;
        height: 340px;    
      }
}
.imgSmall{
    width: 560px;
    height: 320px;
    @media screen and (max-width: 431px) {
        width: 300px;
        height: 240px;    
      }
}