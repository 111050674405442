.details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 600px;
    
     @media screen and (max-width: 431px) {
         width: 300px    
       }
}
.link{
    text-decoration: none;
    color: black;
    font-size: 24px;
    text-transform: uppercase;
    font-weight:400;
    @media screen and (max-width: 431px) {
        font-size: 18px;   
      }
}
.text{
    font-size: 20px;
    @media screen and (max-width: 431px) {
        font-size: 16px;   
      }
}