.data{
    display: flex;
    padding-top: 80px;
    gap: 100px;
  
}
.container{
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    gap: 30px;
}
.input{
    display: flex;
    flex-direction: column;
    gap: 7px;
}