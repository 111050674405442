.registrationIcons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 300px;
}
.text{
    font-size: 18px;
}
.iconsHolder{
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.icon{
    width: 50px;
    height: 50px;
}