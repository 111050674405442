// .loginPage{
//     background-color: rgb(109, 107, 107);
//     opacity: 0.3;
// }
.page{
    display: flex;
    // gap: 100px;
    padding: 250px 200px 200px 200px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0;
}
.login{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    
}
.register{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
   /*  @media screen and (max-width: 821px) {
        display: none;
    } */
}

.errorbar{
    color: red;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
}
.item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.title{
    font-weight: 700px;
    font-size: 26px;
    text-transform: uppercase;
}
.red{
    color: red;
}
.titleInVisible{
    display: none;
}
.titleInVisible{
    display: none;
    @media screen and (max-width: 821px) {
        display: block;
        text-decoration:underline;
    }
}
.loginInVisible{
    display: none;
}
.registerVisible{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
}