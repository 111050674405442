.productsHolder{
    display: flex;
    flex-direction: column;
    gap:50px;
    justify-content: center;
    align-items: center;
}
.button{
    width: 300px;
    height: 80px;
    font-size: 20px;
    box-sizing: border-box;
    border: 3px solid black;
}
.title{
    font-weight: 500;
    font-size: 30px;
}
.products{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}