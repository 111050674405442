.avatar{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-image: url('../../images/969.jpg');
    align-self: center;
}
.person{
    padding-top: 50px;
    padding-left: 20px;
    width: 300px;
    height: 100vh;
    // position: fixed;
    top: 80px;
    left: 0px;
    background-color: gray;
    color: white;
    font-size: 20px; 
    display: flex;
    flex-direction: column;
    gap: 40px;
    @media screen and (max-width: 431px) {
        width: 100vw;
        height: 400px;  
        gap: 20px; 
      }
}
.text{
    text-decoration: none;
    color: white;
}