.errorPage{
    color: var(--white);
    background-color: var(--bg-primary);
    padding: 4vw;
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    gap: 4vw;
    height: 100vh;

    img{
        margin-left: auto;
    }

    .footer{
        margin-left: auto;
        font-size: large;
    }
}