.popover{
    display: flex;
    position: fixed;    
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // width: 400px;
    // height: 400px;
    z-index: 20;
    
}
.popoverContent{
    border: 2px solid gray;
    border-radius: 10px;
    margin: auto; 
    background-color: rgba(97, 95, 95, 0.8);
    color: white;
}
.popoverInVisible{
    display: none;
}