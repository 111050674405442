.productContainer{
    display: flex;
    flex-direction: column;
    // gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 1rem;
   
}
.img{
    width: 300px;
    height: 350px;
}
