.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.title {
    text-align: center;
    font-weight: 400;
}
.formTitle {
    margin: 0;
    font-weight: 400;
}
.inputWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: start;
}

.button {
    display: block;
    margin: 30px auto;
}