.application{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    gap: 60px;
}
.title{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 42px;
    line-height: 1.23;
    text-align: center;
    @media screen and (max-width: 769px) {
        font-size: 32px;      
     }
     @media screen and (max-width: 431px) {
        font-size: 22px;    
      }  
}
.container{
    display: flex;
    gap: 100px;
    padding: 0px 100px 100px 100px;
    @media screen and (max-width: 1025px) {
        flex-direction: column;
        gap: 50px;
        justify-content: center;
        align-items: center;
      }
}
.holder{
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media screen and (max-width: 1025px) {
        display: flex;

        gap: 30px;
      }
}
.point{
    display: flex; 
    gap: 20px;
}
.descriptionHolder{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.subtitle{
    font-weight: 500;
    font-size: 22px;
    line-height: 1.4;
}
.text{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.55;
}
.appImg{
    width: 560px;
    height: 416px;
    @media screen and (max-width: 431px) {
        width: 300px;
        height: 240px;    
      }
}