.textHolder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 12%;
    @media screen and (max-width: 769px) {
        gap: 180px;       
      }
    @media screen and (max-width: 431px) {
        gap: 100px;   
        padding-top: 20vh;    
      }
}
.mainTitle{
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 72px;
    line-height: 1.17;
    @media screen and (max-width: 1025px) {
        font-size: 52px;        
      }
    @media screen and (max-width: 431px) {
        font-size: 32px;         
      }
}
.additionalText{
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1.5;
    color: white;
}
.icon{
    color: white;
    width: 50px;
    height: 50px;
    transform: translateY(10px); 
}
