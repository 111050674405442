.contacts{
    background-color: #afafaf;
    padding: 7vh 15vw 7vh 15vw;
    justify-content: center;
    align-items:center;
    display: flex;
    gap: 7vw;
    @media screen and (max-width: 769px) {
        flex-direction: column;
      }
}
.links{
    display: flex; 
    flex-direction: column;
    margin-top: 10vh;
    gap: 10vh;
}
.title{
    font-size: 42px;
    line-height: 1.23;
}
.text{
    font-size: 24px;
    line-height: 1.5;
    color: white;
}
.detatils{
    display: flex;
    flex-direction: column;
    gap: 4vh;
}
.link{
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
}