.boxNumber{
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px;
    @media screen and (max-width: 431px) {
        width: 300px;
        height: 400px; 
      }
}
.text{
    font-size: 18px;
}
.enterField{
    display: flex;
    gap: 50px;
}
.button{
    width: 100px;
    height: 40px;
}
.explanation{
    font-size: 14px;
}
.boxNumberInVisible{
     display: none;
}
   
