
.header{
     /* margin: 0 auto; */
     width: 100vw;
     height: 80px;
     display: flex;
     justify-content: space-between;
     // background-color: red;
     position: fixed; 
     top: 0;
     z-index: 999999999;
    @media screen and (max-width: 769px) {
        display: none;
        justify-content: flex-end;
        z-index: 10;
      }
    @media screen and (max-width: 820px) and (min-width: 769px) {
        padding-left: 2vh;
      }
}
.headerDark{
    background-color: black;
    opacity: 0.3;    
}
.headerAnotherPage{
    background-color: rgb(196, 194, 194);
}
.menuHolder{
    display: flex;
    gap: 30px;
    align-items: center;
    margin-right:2rem;
}
.menu{
    display: flex;
    gap: 20px;
}

/* .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */

.button {
    display: block;
    margin: 30px auto;
    border-radius: 30px;
    cursor: pointer;
}

// .item{    
    
// }
.itemText{
    color: white;
    text-decoration: none;
    font-size: 18px;  
    font-weight: 400;
    font-family: 'Roboto'; 
}
.item:hover{
    border-bottom: 1px solid #df8c86;
    transition: all 0.3s ease;
    color: black;
}
.itemTextActive{
    color: #781f19;
}
.login{
    width: 35px;
    height: 35px;
}

.mobileHeader{
    display: none;
    @media screen and (max-width: 769px) {
        display: flex;
        justify-content: flex-end;
        z-index: 10;
      }
}
.small{
    display: none;
}
.big{
    position: fixed;
    left: 0px;
    display: flex;
    flex-direction: column;
    
    padding-top: 5vh;
    padding-left: 1vw;
    gap: 100px;
    z-index: 10;
    background-color: gray;
    height: 100vh;
    width: 250px;
}
.menuMobile{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 3vh;
}
.iconsContainer{
    display: flex;
    gap: 20px;
    padding-left: 3vh;
}