.wrapper {
    background-color: #fff;
}
.textWrapper {
    margin: 0 auto;
    padding: 100px;
    display: flex;
    flex-direction: column;
}
.title {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;
}
.text {
    margin: 0;
    font-size: 20px;
    text-align: center;
    margin-bottom: 25px;
}

.btn{
    justify-content: center !important;
    width: fit-content !important;
    margin: auto !important;
}