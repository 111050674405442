.textHolder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    padding: 5% 20% 5% 20%;
}
.text{
    font-weight: 300;
    text-align: center;
    color: black;
    font-size: 26px;
    line-height: 40px;
}
.red{
    color: rgb(147,21,21);
}